@mixin in-mobile {
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin in-desktop {
  @content;
}

// ---

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: #fcfbfa;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  width: 100%;
  font-family: -apple-system, 'Helvetica Neue', sans-serif;
  color: #333;
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
}

a,
a:hover,
a:active,
a:focus,
a:visited {
  color: #55b1ff;
  text-decoration: none;
  outline: none;
  word-break: break-all;
}

.text-highlight {
  display: inline-block;

  @include in-desktop {
    padding-left: 2px;
    padding-right: 2px;
    height: 46px;
    box-shadow: inset 0 -20px 0 #ffdd33;
  }

  @include in-mobile {
    padding-left: 1px;
    padding-right: 1px;
    height: 36px - 1px;
    box-shadow: inset 0 -17px 0 #ffdd33;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;

  @include in-desktop {
    max-width: 960px;
  }

  @include in-mobile {
    max-width: 450px;
  }

  @include in-desktop {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include in-mobile {
    padding-left: 24px;
    padding-right: 24px;
  }
}

header {
  height: 64px;
  padding-top: 6px;
  background-color: #fff;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
  flex-direction: row;
  align-items: center;

  .container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

h1 {
  font-weight: 900;

  @include in-desktop {
    font-size: 40px;
    line-height: 46px;
    letter-spacing: -0.6px;
  }

  @include in-mobile {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.1px;
  }
}

h2 {
  font-weight: 900;

  @include in-desktop {
    font-size: 30px;
    line-height: 36px;
  }

  @include in-mobile {
    font-size: 28px;
    line-height: 36px;
    // letter-spacing: -0.1px;
  }
}

h2.subtitle {
  color: #606060;
  font-weight: 400;

  @include in-desktop {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.2px;
  }

  @include in-mobile {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
  }
}

p {
  color: #606060;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.homepage .s1 {
  @include in-desktop {
    text-align: center;
    padding-top: 120px;
    padding-bottom: 120px;
  }

  @include in-mobile {
    text-align: left;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  h1 {
    @include in-desktop {
      margin-bottom: 24px;
      text-align: center;
    }

    @include in-mobile {
      margin-bottom: 24px;
      text-align: left;
    }
  }

  h2 {
    @include in-desktop {
      margin-bottom: 48px;
      text-align: center;
    }

    @include in-mobile {
      margin-bottom: 48px;
      text-align: left;
    }
  }
}

.feature {
  @include in-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
  }

  @include in-mobile {
    display: block;
  }

  &__image {
    @include in-desktop {
      margin-right: 48px;
    }

    @include in-mobile {
      margin-right: 0;
    }
  }

  &__image > img {
    @include in-desktop {
      width: 400px;
      height: auto;
    }

    @include in-mobile {
      max-width: 470px;
      width: 100%;
      height: auto;
    }
  }

  &__content {
    @include in-desktop {
      max-width: 460px;
      margin-bottom: 48px;
    }

    @include in-mobile {
      max-width: 100%;
      margin-bottom: 0;
    }
  }

  &__sep {
    margin-top: 12px;
    margin-bottom: 24px;
    width: 120px;
    height: 8px;
    background-color: #ffdd33;
  }
}

.download {
  background-color: #faf7f5;

  @include in-desktop {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  @include in-mobile {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  & > .container > div {
    text-align: center;
  }

  .icon-name-vert {
    @include in-desktop {
      margin-bottom: 64px;
    }

    @include in-mobile {
      margin-bottom: 48px;
    }

    & > img {
      @include in-desktop {
        width: 200px;
        height: auto;
      }

      @include in-mobile {
        width: 160px;
        height: auto;
      }
    }
  }
}

footer {
  display: block;
  font-weight: 500;
  color: #606060;
  background-color: #f2f0ed;
  padding-top: 40px;
  padding-bottom: 40px - 16px;
  font-size: 14px;
  line-height: 20px;

  .row {
    margin-bottom: 16px;
  }

  a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #606060;
  }
}

.md {
  h1,
  h2,
  h3 {
    margin-top: 40px;
    margin-bottom: 16px;
  }
  p {
    color: #333;
    font-weight: 400;
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 22px;
  }
  h2 {
    font-size: 24px;
    line-height: 30px;
  }
  ol,
  ul {
    padding-left: 28px;
  }
}
